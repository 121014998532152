import React from 'react'

export default function OwnBook() {
  return (
    <div>
      <section className="own-book-sec">
        <div className="container-fluid">
            <div className="own-book-inner-txt">
                <div className="wrapper-own-book-1">
                    <h4 data-aos="fade-up">Why us? Because Our <span style={{color : "#ee6c4d"}}> Affordable Ghostwriting
                            Services</span> Meet Your Expectations!</h4>
                    <div className="row">
                        <div className="col-lg-6">
                            <p data-aos="fade-up">Do you know that an average person speaks around 860,341,500 words
                                throughout life? Well, if you can speak that much, certainly you can write half of that.
                                Writing can be tiring sometimes, but not for a professional ghostwriter. However, if you
                                are new to this, you might not be familiar with many things. That’s exactly when our
                                affordable ghostwriting services come to the rescue! </p>
                            <p data-aos="fade-up">We all know that writing a huge book isn’t an easy task unless you
                                hire a ghostwriter; only then will the process become easier. The great thing about our
                                top ghostwriting company is that we don’t ask for an arm and a leg to work on your
                                project. We see and understand the passion behind your ideas, so we offer the most
                                affordable service you can ever find. </p>
                            <p data-aos="fade-up">Our team of expert ghostwriters makes sure to provide you with the
                                best writing assistance. We have been in this business for more than a few years now. We
                                started from scratch with a bunch of like-minded people; today, we are here as a
                                professional ghostwriting agency. Every human has that in-build writer, but only some
                                are vocal about it. That’s exactly when you should approach us! </p>
                            <p data-aos="fade-up">Many of you might think that each word written in a book comes from a
                                ghostwriter, but that is merely a misconception. Our professional ghostwriters follow
                                the protocol and instructions the clients give so that everything works smoothly.
                                Penning down someone else’s thoughts surely isn’t an easy job, so it requires a great
                                deal of concentration to be completed. </p>
                        </div>
                        <div className="col-lg-6">
                            <p data-aos="fade-up">Various untold stories surround us, so if you consider yours one of
                                them, we should get in touch already! Because why not? You have stories, and we have
                                writers; what else is required anyway? You can come up with many scrambled pages, and we
                                will still turn that into a masterpiece.</p>
                            <p data-aos="fade-up">By utilizing our budget-friendly ghostwriting service, you're in the
                                driver's seat. It's your story, after all! Feel free to tweak, add, or remove anything
                                you like as we go along.</p>
                            <p data-aos="fade-up">Thinking about writing a book and actually ghostwriting a book are two
                                different things. However, we have a different take on this one. If you are thinking
                                about it, you are halfway there. Manifestation can lead you to unexpected places -
                                better ones! And in this case, all you have to do is to manifest and leave the rest to
                                us. We know how to turn tables. </p>
                            <p data-aos="fade-up">So if you are on it with us, we can make big things happen quickly.
                                Today you might be just another person walking through the hallways of your university.
                                Still, once you get your book written, edited, and published by a team of
                                ProfessionalWritersHelp, you’ll become the start of those same hallways. People will
                                start noticing you. Now, who wouldn’t want to be appreciated and recognized like that?
                            </p>
                        </div>
                    </div>
                </div>
                <div className="wrapper-own-book-2">
                    <h4>How to Spot Your Perfect Ghostwriters for Hire?</h4>
                    <div className="row">
                        <div className="col-lg-6">
                            <ul>
                                <li data-aos="fade-up">Now this can be tricky since everyone calls himself a writer
                                    after publishing a piece of paper. But you don’t have to worry about it anymore
                                    since differentiating between a professional and a common writer has become easy.
                                    However, we’d still like to state some facts to give you a clearer picture of a
                                    professional ghostwriter. </li>
                                <li data-aos="fade-up">The first and foremost thing to keep a check on while hiring a
                                    ghostwriter is time management. A person new to writing might take a good amount of
                                    time to settle their thoughts. However, a ghostwriter wouldn’t require extra days to
                                    hand you over your book. Since they have been in the business for years, they know
                                    how to manage everything and keep up with the pace.</li>
                                <li data-aos="fade-up">The working manner of a ghostwriter is slightly different from a
                                    normal content writer, but that does not mean they aren’t serious with their work.
                                    You may never find anyone more serious than them for your project. Nonetheless, if
                                    you want your book to be perfect without any major changes, you are supposed to make
                                    it clear before hiring a ghostwriter. Although our team of experts doesn’t create
                                    unnecessary changes, providing all the essential data beforehand is better. </li>
                            </ul>
                        </div>
                        <div className="col-lg-6">
                            <ul>
                                <li data-aos="fade-up">Finding a ghostwriter through freelance platforms can be easier,
                                    but their commitment to your project can not be guaranteed. However, you won't have
                                    to worry about anything if you approach a ghostwriter through an agency with an
                                    impressive track record and testimonials. If a writer is associated with a trusted
                                    agency, he’ll be completely committed to your project. Additionally, ghostwriting
                                    agencies take full responsibility for their work and keep you updated. </li>
                                <li data-aos="fade-up">There’s the quality of work because no one wants to compromise. A
                                    professional ghostwriter is experienced enough to take over any project without
                                    hesitation. It doesn’t mean you hire the first person you come across. Expand your
                                    horizon and find the one who assures you he can work on your niche. </li>
                                <li data-aos="fade-up">That said, hiring a perfect writer depends on your research
                                    skills. Without thorough research, you won’t be able to get your desired results.
                                    Hence, as they say, better safe than sorry; consider everything before you hand over
                                    your project to any writer. </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}
