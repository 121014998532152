import React, {useState} from 'react'
import CtaImg1 from '../../assets/images/cta-img-1.webp';
import CtaBefore from '../../assets/images/cta-before.webp';
import ModalForm from '../modalPopup';

export default function CtaTwo() {
  const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };
  return (
    <div>
        <section className="cta_two white_hd">
            <div className="container cta_two_wrap position-relative" data-aos="fade-up" data-aos-duration="1500">
                <h2>Let’s Write Your Book</h2>
                <p>Share your idea with us. Our writers will bring it to life for you.</p>
                <a href="javascript:;" onClick={onOpenModal}  className="default_btn white_btn hvr-radial-in open_popup">Request Free Consultation</a>
                <a href="mailto:info@professionalwritershelp.co" className="default_btn">info@professionalwritershelp.co</a>
                <img loading="lazy" src={CtaImg1} alt="Book for Publishing" className="cta_two_img3"/>
            </div>
            <img loading="lazy" src={CtaBefore} alt="Book Mockup" className="cta_two_img1"/>
        </section>
        <ModalForm open={open} onCloseModal={onCloseModal} />
    </div>
  )
}
