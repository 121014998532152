import React from 'react';

export default function Services() {
  return (
    <div>
      <section className="comprehensive-serv">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-12 px-4">
                    <div className="comp-serv text-center">
                        <h5 data-aos="fade-up">Make The Most Out of Our <br/>Premium Ghostwriting and Editing Services
                        </h5>
                        <div className="compr-serv-boxes">
                            <div className="row no-gutters compr-serv-boxes-slider">
                                <div className="col-lg-3">
                                    <div className="compr-serv-box" data-aos="fade-right">
                                        <i className="sprite-compr sprite-compr-icon-1"></i>
                                        <a href="story-writing">
                                            <h3>Story writing</h3>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="compr-serv-box" data-aos="fade-right">
                                        <i className="sprite-compr sprite-compr-icon-2"></i>
                                        <a href="song-writing">
                                            <h3>Song Writing</h3>
                                        </a>

                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="compr-serv-box" data-aos="fade-right">
                                        <i className="sprite-compr sprite-compr-icon-3"></i>
                                        <a href="memoir-writing">
                                            <h3>Memoir writing</h3>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="compr-serv-box" data-aos="fade-right">
                                        <i className="sprite-compr sprite-compr-icon-4"></i>
                                        <a href="nonfiction-writing">
                                            <h3>Non-fiction writing</h3>
                                        </a>

                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="compr-serv-box" data-aos="fade-right">
                                        <i className="sprite-compr sprite-compr-icon-5"></i>
                                        <a href="biography-writing">
                                            <h3>Biography writing</h3>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="compr-serv-box" data-aos="fade-right">
                                        <i className="sprite-compr sprite-compr-icon-6"></i>
                                        <a href="autobiography-writing">
                                            <h3>Autobiography writing</h3>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="compr-serv-box" data-aos="fade-right">
                                        <i className="sprite-compr sprite-compr-icon-7"></i>
                                        <a href="seo-content-writing">
                                            <h3>SEO Writing</h3>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="compr-serv-box" data-aos="fade-right">
                                        <i className="sprite-compr sprite-compr-icon-8"></i>
                                        <a href="hiphop-writing">
                                            <h3>Hip Hop/Rap Writing</h3>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="compr-serv-box" data-aos="fade-right">
                                        <i className="sprite-compr sprite-compr-icon-9"></i>
                                        <a href="comic-writing">
                                            <h3>Comic Writingg</h3>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="compr-serv-box" data-aos="fade-right">
                                        <i className="sprite-compr sprite-compr-icon-10"></i>
                                        <a href="novel-writing">
                                            <h3>Novel Writing</h3>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="compr-serv-box" data-aos="fade-right">
                                        <i className="sprite-compr sprite-compr-icon-11"></i>
                                        <a href="speech-writing">
                                            <h3>Speech writing</h3>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="compr-serv-box" data-aos="fade-right">
                                        <i className="sprite-compr sprite-compr-icon-12"></i>
                                        <a href="script-writing">
                                            <h3>Script Writing</h3>
                                        </a>

                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="compr-serv-box" data-aos="fade-right">
                                        <i className="sprite-compr sprite-compr-icon-13"></i>
                                        <a href="military-fiction-writing">
                                            <h3>Military Fiction Writing</h3>
                                        </a>

                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="compr-serv-box" data-aos="fade-right">
                                        <i className="sprite-compr sprite-compr-icon-14"></i>
                                        <a href="wiki-writing">
                                            <h3>Wikipedia Writing</h3>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="compr-serv-box" data-aos="fade-right">
                                        <i className="sprite-compr sprite-compr-icon-15"></i>
                                        <a href="movie-writing">
                                            <h3>Movie Writing</h3>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="compr-serv-box" data-aos="fade-right">
                                        <i className="sprite-compr sprite-compr-icon-16"></i>
                                        <a href="screen-writing">
                                            <h3>Screen Writing</h3>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}
