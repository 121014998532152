
import React, { useState,useEffect } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import FlipCountdown from '@rumess/react-flip-countdown';
import { img } from '../../assets';
import axios from 'axios';

export default function ModalForm({open, onCloseModal}) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [ip, setIp] = useState('');
  const [country, setCountry] = useState('')


  const [formData, setFormData] = useState({
    name: '',
    email: '',
    // message: "",
    phone: '',
  });

  // const onSubmitFunc = async (e) => {
  //   e.preventDefault();

  //   console.log('formData', formData);
  //   if (formData.phone.length < 9) {
  //     setErrors(true);
  //   } else {
  //     const currentUrl = window.location.href;

  //     const protocol = window.location.protocol; // "https:"
  //     const hostname = window.location.hostname; // "professionalwritershelp.co"

  //     // Construct the base URL
  //     const baseUrl = `${protocol}//${hostname}`;
  //     const queryStringFormData = new URLSearchParams(formData).toString();

  //     let finalReq = {
  //       ...formData,
  //       source: `https://professionalwritershelp.co/${queryStringFormData}`,
  //       domain: baseUrl,
  //       lead_url: baseUrl,
  //       url: `https://professionalwritershelp.co/${queryStringFormData}`,
  //     };

  //     try {
  //       setLoading(true);
  //       const apiUrl = 'https://contact-form-we93.onrender.com/contact/send';
  //       const config = {
  //         headers: {
  //           'Content-Type': 'application/x-www-form-urlencoded',
  //         },
  //       };

  //       const formDataString = new URLSearchParams(finalReq).toString();

  //       const response = await axios.post(apiUrl, formData);
  //       if (response.status == 200) {
  //         // toast.success("Thank you for filling out the information");
  //         // console.log(response.data.data.id)
  //         const queryString = new URLSearchParams(
  //           response.data.data
  //         ).toString();
  //         console.log('queryString', queryString);
  //         setLoading(false);
  //         onCloseModal();
  //         // naviagte(`/thank-you?${queryString}`)
  //         window.location.href = `https://professionalwritershelp.co/thankyou?${queryString}`;
  //         // router.push('/thankyou');
  //       }
  //       console.log('responseresponseresponse', response);
  //     } catch (error) {
  //       console.error('Error:', error);
  //     }
  //   }
  // };



  useEffect(() => {
    const fetchIp = async () => {
      try {
        const ipResponse = await axios.get('https://geolocation-db.com/json/');
        setIp(ipResponse.data.IPv4);
        setCountry(ipResponse.data.country_name);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    fetchIp();
  }, []);

  const onSubmitFunc = async (e) => {
    e.preventDefault();
  
    console.log('formData', formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;
  
      const protocol = window.location.protocol; // "https:"
      const hostname = window.location.hostname; // "professionalwritershelp.co"
      
      // Construct the base URL
      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();
  
      let finalReq = {
        ...formData,
        lead_url: window.location.href,
        ip: ip,
        country: country,
      };
  
      try {
        setLoading(true);
  
        // Fetch the IP address
        // const ipResponse = await axios.get('https://ipapi.co/json');
        // const ip = ipResponse.data.ip;
  
        // // Add the IP address to the finalReq object
        // finalReq.ip = ip;
  
        const apiUrl = 'https://contact-form-we93.onrender.com/contact/send';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
  
        const formDataString = new URLSearchParams(finalReq).toString();
  
        const response = await axios.post(apiUrl, finalReq, config);
        if (response.status === 200) {
          // toast.success("Thank you for filling out the information");
          // console.log(response.data.data.id)
          const queryString = new URLSearchParams(response.data.data).toString();
          console.log('queryString', queryString);
          setLoading(false);
          onCloseModal();
          // navigate(`/thank-you?${queryString}`)
          window.location.href = `https://professionalwritershelp.co/thankyou?${queryString}`;
          // router.push('/thankyou');
        }
        console.log('responseresponseresponse', response);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };




  return (
    <div>
      <Modal open={open} onClose={onCloseModal} center>
        <div class="modal-dialog modal-dialog-centered">
          <div class="entry-box text-center">
              <div class="popup-form-wrap lazy-background visible">
                <div class="popup-form-img">
                  <button
                    type="button"
                    name="close"
                    className="close"
                    id="bio_ep_close"
                    aria-label="close"
                    data-dismiss="modal"
                    onClick={onCloseModal}
                  >
                    ×
                  </button>
                  {/* <button type="button" class="close" id="bio_ep_close" data-dismiss="modal"
                                aria-label="Close">×</button> */}
                </div>
                <h2>
                  Launch <br />
                  <span>Your Book</span>
                </h2>
                <div class="timmer_content">
                  {/* <ul class="flip-clock-container flip-2">
                    <li class="flip-item-seconds">00</li>
                    <li class="flip-item-minutes">42</li>
                    <li class="flip-item-hours">1</li>
                  </ul> */}
                    <FlipCountdown
                        theme='light' // Options (Default: dark): dark, light.
                        endAt={'2022-12-12 01:26:58'} // Date/Time
                    />
                </div>
                <div class="discount-label">
                  <h3>
                    40% <span>Discount</span>
                  </h3>
                </div>
                <p>
                  Get Your Name In The List Of <span>Best-Selling Authors</span>{' '}
                </p>
                <div class="form-box-wrap">
                  <form onSubmit={onSubmitFunc}>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Your Name"
                        name="name"
                        required
                        onChange={(e) =>
                            setFormData({ ...formData, name: e.target.value })
                          }
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Enter Your Email"
                        name="email"
                        required
                        onChange={(e) =>
                            setFormData({ ...formData, email: e.target.value })
                          }
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="number"
                        class="form-control"
                        minLength={9}
                        min={9}
                        placeholder="Enter Your Phone"
                        name="phone"
                        required
                        onChange={(e) =>
                            setFormData({ ...formData, phone: e.target.value })
                          }
                      />
                      {errors && <div className="error" style={{color: "white"}}>Phone number must be at least 9 digits long</div>}
                    </div>

                    <div class="clearfix fieldwrap">

                        {loading == true ? (
                            <img src={img.logoGif} alt="gif" width={"35px"}/>
                        ): (
                            <button
                            type="submit"
                            className="btn-validate form_btn"
                            name="signupForm"
                            aria-label="signupForm"
                            id="signupBtn"
                            // value={1}
                            disabled={loading}
                        >
                            Submit
                        </button>
                        )}

                      

                    </div>
                    
                  </form>
                </div>
              </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
