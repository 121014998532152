"use client";

import React, { useState } from "react";
import { img} from "../../assets";
import ModalForm from '../modalPopup';

import Slider from "react-slick";

export default function OurExpertise() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    margin: 25,
  };
  const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };
  return (
    <div>
      <section class="services sec_pb sec_pt">
        <div class="container">
          <div
            class="sec_title text-center"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <h2>OUR EXPERTISE</h2>
            <p>
              Don’t just take our word for it, instead, have a look at the
              services we provide for a better insight!
            </p>
          </div>
          <Slider {...settings} className="services_slider">
            <div class="item">
              <div class="service_box">
                <img loading="lazy" src={img.exp01} alt="STORY WRITING" />
                <h3>STORY WRITING </h3>
                <p>
                  The world wants to know how imaginative you can be. Use our
                  story-writing services to revive your inner storyteller and
                  let the world know how thoughts can be magical sometimes.{" "}
                </p>
                <a href="javascript:;"  onClick={onOpenModal}>Read More</a>
              </div>
            </div>
            <div class="item">
              <div class="service_box">
                <img loading="lazy" src={img.exp02} alt="NON FICTION" />
                <h3>NON FICTION </h3>
                <p>
                  When was the last time you decided to publish a book? Forget
                  about that; now is the time you should already start working
                  on that!
                </p>
                <a href="javascript:;"  onClick={onOpenModal}>Read More</a>
              </div>
            </div>
            <div class="item">
              <div class="service_box">
                <img loading="lazy" src={img.exp03} alt="RHYMES WRITING" />
                <h3>RHYMES WRITING</h3>
                <p>
                  Don’t let those symphonies ring inside your head; instead,
                  allow us to enjoy the rhythm or create one for you. Why?
                  Because we know that poetry is food for the soul.
                </p>
                <a href="javascript:;"  onClick={onOpenModal}>Read More</a>
              </div>
            </div>
            <div class="item">
              <div class="service_box">
                <img loading="lazy" src={img.exp04} alt="EBOOK WRITING" />
                <h3>EBOOK WRITING</h3>
                <p>
                  Looking for someone who can provide you with an impeccable
                  piece of content for your ebook? Just give us the details and
                  consider it done in the meantime.
                </p>
                <a href="javascript:;"  onClick={onOpenModal}>Read More</a>
              </div>
            </div>
            <div class="item">
              <div class="service_box">
                <img loading="lazy" src={img.exp05} alt="BIOGRAPHY" />
                <h3>BIOGRAPHY</h3>
                <p>
                  Today, tomorrow, or any other day, we know you have already
                  decided to publish your biography. Hire a biography writer
                  today so that you can start inspiring people tomorrow!
                </p>
                <a href="javascript:;">Read More</a>
              </div>
            </div>
            <div class="item">
              <div class="service_box">
                <img loading="lazy" src={img.exp06} alt="AUTOBIOGRAPHY" />
                <h3>AUTOBIOGRAPHY </h3>
                <p>
                  Do you have a story that can change many lives? You need to
                  publish your autobiography ASAP! Our ghostwriters will help
                  you with that!
                </p>
                <a href="javascript:;"  onClick={onOpenModal}>Read More</a>
              </div>
            </div>
          </Slider>
        </div>
      </section>
      <ModalForm open={open} onCloseModal={onCloseModal} />
    </div>
  );
}
