import allTab1 from './images/portfolio/all-tab/01.webp';
import allTab2 from './images/portfolio/all-tab/02.webp';
import allTab3 from './images/portfolio/all-tab/03.webp';
import allTab4 from './images/portfolio/all-tab/04.webp';
import allTab5 from './images/portfolio/all-tab/05.webp';
import allTab6 from './images/portfolio/all-tab/06.webp';
import allTab7 from './images/portfolio/all-tab/07.webp';
import allTab8 from './images/portfolio/all-tab/08.webp';
import allTab9 from './images/portfolio/all-tab/09.webp';
import allTab10 from './images/portfolio/all-tab/10.webp';
import allTab11 from './images/portfolio/all-tab/11.webp';
import allTab12 from './images/portfolio/all-tab/12.webp';

import Bio1 from './images/portfolio/bio/01.webp';
import Bio2 from './images/portfolio/bio/02.webp';
import Bio3 from './images/portfolio/bio/03.webp';
import Bio4 from './images/portfolio/bio/04.webp';
import Bio5 from './images/portfolio/bio/05.webp';
import Bio6 from './images/portfolio/bio/06.webp';
import Bio7 from './images/portfolio/bio/07.webp';
import Bio8 from './images/portfolio/bio/08.webp';
import Bio9 from './images/portfolio/bio/09.webp';
import Bio10 from './images/portfolio/bio/10.webp';
import Bio11 from './images/portfolio/bio/11.webp';
import Bio12 from './images/portfolio/bio/12.webp';

import childBook1 from './images/portfolio/child-book/01.webp';
import childBook2 from './images/portfolio/child-book/02.webp';
import childBook3 from './images/portfolio/child-book/03.webp';
import childBook4 from './images/portfolio/child-book/04.webp';
import childBook5 from './images/portfolio/child-book/05.webp';
import childBook6 from './images/portfolio/child-book/06.webp';
import childBook7 from './images/portfolio/child-book/07.webp';
import childBook8 from './images/portfolio/child-book/08.webp';
import childBook9 from './images/portfolio/child-book/09.webp';
import childBook10 from './images/portfolio/child-book/10.webp';
import childBook11 from './images/portfolio/child-book/11.webp';
import childBook12 from './images/portfolio/child-book/12.webp';

import Fiction1 from './images/portfolio/fiction/01.webp';
import Fiction2 from './images/portfolio/fiction/02.webp';
import Fiction3 from './images/portfolio/fiction/03.webp';
import Fiction4 from './images/portfolio/fiction/04.webp';
import Fiction5 from './images/portfolio/fiction/05.webp';
import Fiction6 from './images/portfolio/fiction/06.webp';
import Fiction7 from './images/portfolio/fiction/07.webp';
import Fiction8 from './images/portfolio/fiction/08.webp';
import Fiction9 from './images/portfolio/fiction/09.webp';
import Fiction10 from './images/portfolio/fiction/10.webp';
import Fiction11 from './images/portfolio/fiction/11.webp';
import Fiction12 from './images/portfolio/fiction/12.webp';

import Informative1 from './images/portfolio/informative/01.webp';
import Informative2 from './images/portfolio/informative/02.webp';
import Informative3 from './images/portfolio/informative/03.webp';
import Informative4 from './images/portfolio/informative/04.webp';
import Informative5 from './images/portfolio/informative/05.webp';
import Informative6 from './images/portfolio/informative/06.webp';
import Informative7 from './images/portfolio/informative/07.webp';
import Informative8 from './images/portfolio/informative/08.webp';
import Informative9 from './images/portfolio/informative/09.webp';
import Informative10 from './images/portfolio/informative/10.webp';
import Informative11 from './images/portfolio/informative/11.webp';
import Informative12 from './images/portfolio/informative/12.webp';

import Memoir1 from './images/portfolio/memoir/01.webp';
import Memoir2 from './images/portfolio/memoir/02.webp';
import Memoir3 from './images/portfolio/memoir/03.webp';
import Memoir4 from './images/portfolio/memoir/04.webp';
import Memoir5 from './images/portfolio/memoir/05.webp';
import Memoir6 from './images/portfolio/memoir/06.webp';
import Memoir7 from './images/portfolio/memoir/07.webp';
import Memoir8 from './images/portfolio/memoir/08.webp';
import Memoir9 from './images/portfolio/memoir/09.webp';
import Memoir10 from './images/portfolio/memoir/10.webp';
import Memoir11 from './images/portfolio/memoir/11.webp';
import Memoir12 from './images/portfolio/memoir/12.webp';

import nonBio1 from './images/portfolio/non-bio/01.webp';
import nonBio2 from './images/portfolio/non-bio/02.webp';
import nonBio3 from './images/portfolio/non-bio/03.webp';
import nonBio4 from './images/portfolio/non-bio/04.webp';
import nonBio5 from './images/portfolio/non-bio/05.webp';
import nonBio6 from './images/portfolio/non-bio/06.webp';
import nonBio7 from './images/portfolio/non-bio/07.webp';
import nonBio8 from './images/portfolio/non-bio/08.webp';
import nonBio9 from './images/portfolio/non-bio/09.webp';
import nonBio10 from './images/portfolio/non-bio/10.webp';
import nonBio11 from './images/portfolio/non-bio/11.webp';
import nonBio12 from './images/portfolio/non-bio/12.webp';

import portIcon from './images/port-icon.webp';

import exp01 from './images/service-icon1.webp';
import exp02 from './images/service-icon2.webp';
import exp03 from './images/service-icon3.webp';
import exp04 from './images/service-icon4.webp';
import exp05 from './images/service-icon5.webp';
import exp06 from './images/service-icon6.webp';

import logoGif from './images/loader.gif';


import AboutImg1 from "./images/about-img1.webp";
import AboutImg2 from "./images/about-img2.webp";
import AboutImg3 from "./images/about-img3.webp";
import AboutImg4 from "./images/about-img4.webp";
import AboutGirl from "./images/about-girl.webp";

import Logo from './images/logo-new.webp';
import PhoneIcon from './images/call-icon.webp';
import EmailIcon from './images/email-icon.webp';




export const img={
  allTab1,
  allTab2,
  allTab3,
  allTab4,
  allTab5,
  allTab6,
  allTab7,
  allTab8,
  allTab9,
  allTab10,
  allTab11,
  allTab12,

  Bio1,
  Bio2,
  Bio3,
  Bio4,
  Bio5,
  Bio6,
  Bio7,
  Bio8,
  Bio9,
  Bio10,
  Bio11,
  Bio12,

  childBook1,
  childBook2,
  childBook3,
  childBook4,
  childBook5,
  childBook6,
  childBook7,
  childBook8,
  childBook9,
  childBook10,
  childBook11,
  childBook12,

  Fiction1,
  Fiction2,
  Fiction3,
  Fiction4,
  Fiction5,
  Fiction6,
  Fiction7,
  Fiction8,
  Fiction9,
  Fiction10,
  Fiction11,
  Fiction12,

  Informative1,
  Informative2,
  Informative3,
  Informative4,
  Informative5,
  Informative6,
  Informative7,
  Informative8,
  Informative9,
  Informative10,
  Informative11,
  Informative12,

  Memoir1,
  Memoir2,
  Memoir3,
  Memoir4,
  Memoir5,
  Memoir6,
  Memoir7,
  Memoir8,
  Memoir9,
  Memoir10,
  Memoir11,
  Memoir12,

  nonBio1,
  nonBio2,
  nonBio3,
  nonBio4,
  nonBio5,
  nonBio6,
  nonBio7,
  nonBio8,
  nonBio9,
  nonBio10,
  nonBio11,
  nonBio12,

  portIcon,

  exp01,
  exp02,
  exp03,
  exp04,
  exp05,
  exp06,




  logoGif,

  AboutImg1,
  AboutImg2,
  AboutImg3,
  AboutImg4,
  AboutGirl,
  Logo,
  PhoneIcon,
  EmailIcon

}
