import React, { useState, useEffect } from 'react';
import FooterFormImg from '../../assets/images/footer-form-img.webp';
import LoaderImg from '../../assets/images/loader.gif';
import axios from 'axios';

export default function ContactForm() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [ip, setIp] = useState('');
  const [country, setCountry] = useState('')
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
  });

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const ipResponse = await axios.get('https://geolocation-db.com/json/');
        setIp(ipResponse.data.IPv4);
        setCountry(ipResponse.data.country_name);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    fetchIp();
  }, []);
  const onSubmitFunc = async (e) => {
    e.preventDefault();

    console.log('formData', formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;
      const protocol = window.location.protocol; // "https:"
      const hostname = window.location.hostname; // "professionalwritershelp.co"

      // Construct the base URL
      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        lead_url: window.location.href,
        ip: ip,
        country: country,
      };
  
      try {
        setLoading(true);
        const apiUrl = 'https://contact-form-we93.onrender.com/contact/send';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };

        const formDataString = new URLSearchParams(finalReq).toString();

        const response = await axios.post(apiUrl, finalReq,config);
        if (response.status == 200) {
          // toast.success("Thank you for filling out the information");
          // console.log(response.data.data.id);
          const queryString = new URLSearchParams(
            response.data.data
          ).toString();
          console.log('queryString', queryString);
          setLoading(false);
          setErrors(false);
          setFormData({
            name: '',
            email: '',
            message: '',
            phone: '',
          });
          // naviagte(`/thank-you?${queryString}`)
          window.location.href = `https://professionalwritershelp.co/thankyou?${queryString}`;

        }
        console.log('responseresponseresponse', response);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  return (
    <div>
      <section class="footer_form sec_pt sec_pb">
        <div class="container">
          <div class="row justify-content-end">
            <div
              class="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <h2>Get In Touch With Us</h2>
              <p>
                Fill out your details below and we’ll get back to you shortly.
              </p>
              <form class="clearfix form_submission" onSubmit={onSubmitFunc}>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control required"
                    placeholder="Enter Your Name"
                    name="name"
                    required
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                </div>
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Enter Your Email"
                    name="email"
                    required
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </div>
                <div class="form-group">
                  <input
                    type="number"
                    class="form-control"
                    max="999999999999"
                    placeholder="Enter Your Phone"
                    name="phone"
                    minLength={9}
                    min={9}
                    required
                    onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                    }
                  />
                </div>
                <div class="form-group">
                  <textarea
                    name="message"
                    class="form-control"
                    rows="4"
                    placeholder="Enter Your Message"
                    onChange={(e) =>
                        setFormData({
                          ...formData,
                          message: e.target.value,
                        })
                      }
                  ></textarea>
                </div>
                <div class="clearfix fieldwrap">
                {loading == true ? (
                    <img src={LoaderImg} alt="gif" width={"35px"} />
                ): (<input
                    type="submit"
                    class="btn-validate form_btn"
                    value="Submit"
                  />)}
                  
                </div>
              </form>
            </div>
            <div
              class="col-lg-6 footer_form_img_sect"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <img loading="lazy" src={FooterFormImg} alt="Hand Writing" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
