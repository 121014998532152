import React from 'react';
import FeatureImg1 from '../../assets/images/feature-icon1.webp';
import FeatureImg2 from '../../assets/images/feature-icon2.webp';
import FeatureImg3 from '../../assets/images/feature-icon3.webp';
import FeatureImg4 from '../../assets/images/feature-icon4.webp';

export default function Feature() {
  return (
    <div>
      <section className="feature_sec sec_pt sec_pb">
        <div className="container">
            <div className="sec_title text-center" data-aos="fade-down" data-aos-duration="1500">
                <h2>Our Ghostwriting Team Provide Top Notch Services</h2>
            </div>
            <div className="row feature_sec_Slider">
                <div className="col-lg-3 col-md-6 col-sm-6" data-aos="fade-right" data-aos-delay="200"
                    data-aos-duration="1500">
                    <div className="feature_box">
                        <img loading="lazy" src={FeatureImg1} alt="Quality Assurance"/>
                        <h5>Quality Assurance</h5>
                        <p>They say you must keep a quality check, which our writers live by exactly. However, this
                            isn’t the case only. Our ghostwriters are open to every suggestion because they know there
                            is always room for improvement. </p>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6" data-aos="fade-right" data-aos-delay="500"
                    data-aos-duration="1500">
                    <div className="feature_box">
                        <img loading="lazy" src={FeatureImg2} alt="Quick Turnaround Time"/>
                        <h5>Quick Turnaround Time</h5>
                        <p>It might be slower than sound or light, but our ghostwriting services assure you the fastest
                            turnaround time. We understand that time is valuable; hence using it wisely is our topmost
                            priority. </p>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6" data-aos="fade-right" data-aos-delay="800"
                    data-aos-duration="1500">
                    <div className="feature_box">
                        <img loading="lazy" src={FeatureImg3} alt="24/7 Customer Support"/>
                        <h5>24/7 Customer Support</h5>
                        <p>Whether you live on the other side of the globe or just around the corner, day or night, you
                            can call us anytime. Our customer support will be at your disposal 24/7. </p>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6" data-aos="fade-right" data-aos-delay="1100"
                    data-aos-duration="1500">
                    <div className="feature_box">
                        <img loading="lazy" src={FeatureImg4} alt="Countless Revision"/>
                        <h5>Countless Revision</h5>
                        <p>The ghostwriting services do not require any revision. We still believe in the betterment of
                            anything. That’s why we offer revisions. Don’t hesitate to return to us if it needs changes.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}
