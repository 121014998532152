import React, {useState, useEffect} from 'react';
import newBook1 from '../../assets/images/new-banner-book1.webp';
import newBook2 from '../../assets/images/new-banner-book2.webp';
import newBook3 from '../../assets/images/new-banner-book3.webp';
import newBook4 from '../../assets/images/new-banner-book4.webp';
import newBook5 from '../../assets/images/new-banner-book5.webp';
import newBook6 from '../../assets/images/new-banner-book6.webp';
import newBook7 from '../../assets/images/new-banner-book7.webp';
import newBook8 from '../../assets/images/new-banner-book8.webp';
import bannerLogos from '../../assets/images/banner-logos.webp';
import Loader from '../../assets/images/loader.gif';
import axios from 'axios';
import ModalForm from '../../module/modalPopup';


export default function Banner() {

    // const naviagte = useNavigate();
  const [loading, setLoading] = useState(false);

  const [countstep, setCountStep] = useState(0);
  const [errors, setErrors] = useState(false);

  const [open, setOpen] = useState(false);

  const onOpenModal = () => {
      setOpen(true);
  };

  const onCloseModal = () => {
      setOpen(false);
  };
  const [ip, setIp] = useState('');
  const [country, setCountry] = useState('')
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const handleNextClick = () => {
    // setCountStep((prevStep) => (prevStep < 2 ? prevStep + 1 : prevStep));
    let canProceed = false;

    switch (countstep) {
      case 0:
        canProceed = formData.name.trim() !== "";
        break;
      case 1:
        canProceed = formData.email.trim() !== "";
        break;
      case 2:
        canProceed = formData.phone.trim() !== "";
        break;
      default:
        canProceed = true;
    }

    if (canProceed) {
      setCountStep((prevStep) => (prevStep < 2 ? prevStep + 1 : prevStep));
    } else {
      if (countstep == 0) {
        alert("Please fill this name required field.");
      } else if (countstep == 1) {
        alert("Please fill this email required field.");
      } else {
        alert("Please fill this phone required field.");
      }
    }
  };

    const handlePrevClick = () => {
        setCountStep((prevStep) => (prevStep > 0 ? prevStep - 1 : prevStep));
      };
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
      };




      useEffect(() => {
        const fetchIp = async () => {
          try {
            const ipResponse = await axios.get('https://geolocation-db.com/json/');
            setIp(ipResponse.data.IPv4);
            setCountry(ipResponse.data.country_name);
          } catch (error) {
            console.error('Error fetching IP address:', error);
          }
        };
    
        fetchIp();
      }, []);



    const onSubmitFunc = async (e) => {
        e.preventDefault();
    
        console.log("formData", formData);
        if (formData.phone.length < 9) {
          setErrors(true);
        } else {
          const currentUrl = window.location.href;
    
          const protocol = window.location.protocol;  // "https:"
          const hostname = window.location.hostname;  // "professionalwritershelp.co"
          
          // Construct the base URL
          const baseUrl = `${protocol}//${hostname}`
          // console.log("baseUrl",baseUrl);
    
          // Log the URL to the console (optional)
          console.log(currentUrl);
    
          const queryStringFormData = new URLSearchParams(formData).toString()
    
    
          let finalReq = {
            ...formData,
            lead_url: window.location.href,
            ip: ip,
            country: country,
          };
          try {
            setLoading(true);
            const apiUrl = "http://localhost:5001/contact/send";
            const config = {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            };
               console.log("finalReq",finalReq);
            const formDataString = new URLSearchParams(finalReq).toString();
            console.log("formDataString",formDataString);
    
            const response = await axios.post(apiUrl, finalReq, config);
            if (response.status == 200) {
              // toast.success("Thank you for filling out the information");
              console.log("response", response.data.data);
              const queryString = new URLSearchParams(
                response.data.data
              ).toString();
              console.log("queryString", queryString);
              //  console.log(`/thank-you?${queryString}`);
              // console.log(response.data.data.id);
              setLoading(false);
              setErrors(false);
              setFormData({
                name: "",
                email: "",
                phone: "",
              });
              setCountStep(0);
              // window.location.href=`http://localhost:3000/thank-you?${queryString}`
              window.location.href = `https://professionalwritershelp.co/thankyou?${queryString}`;
            }
            console.log("responseresponseresponse", response);
          } catch (error) {
            console.error("Error:", error);
          }
        }
      };

  return (
    <div>
      <section className="banner">
        <div className="banner_left_img">
            <div className="banner_img_box img-box-one" data-aos="fade-down-right" data-aos-duration="1500"
                data-aos-delay="800">
                <img loading="lazy" src={newBook1} className="banner_img1" alt="Chase Your Dreams"/>
            </div>
            <div className="banner_img_box img-box-two" data-aos="fade-down-right" data-aos-duration="1500"
                data-aos-delay="500">
                <img loading="lazy" src={newBook2} className="banner_img1" alt="Descending Storm"/>
            </div>
            <div className="banner_img_box img-box-three" data-aos="fade-down-right" data-aos-duration="1500"
                data-aos-delay="500">
                <img loading="lazy" src={newBook3} className="banner_img1" alt="A Tsunami of Random Poems"/>
            </div>
            <div className="banner_img_box img-box-four" data-aos="fade-down-right" data-aos-duration="1500"
                data-aos-delay="200">
                <img loading="lazy" src={newBook4} className="banner_img1" alt="After the Storm"/>
            </div>
        </div>

        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8" data-aos="fade-up" data-aos-duration="1500">
                    <div className="banner_content text-center">
                        <h1>Hire Professional <span>Ghostwriting Services in USA!</span></h1>
                        <p>If you have a story, you must get in touch with us! Why? Our professional ghostwriting
                            services can take your accounts to new heights!</p>
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-10 col-sm-10">
                                <div className="banner_form_area">
                                    <form className="form_submission" onSubmit={onSubmitFunc}>
                                        <div className="form_wrap">
                                            <div className="input_fields">
                                            {countstep === 0 ? (
                                                <div
                                                className="form-group side-form-group tab tab-1"
                                                style={{ display: "block" }}
                                                >
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="name"
                                                    placeholder="Enter Your Name"
                                                    required
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                />
                                                </div>
                                            ) : countstep === 1 ? (
                                                <div
                                                className="form-group side-form-group tab tab-1"
                                                style={{ display: "block" }}
                                                >
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    name="email"
                                                    placeholder="Enter Your Email"
                                                    required
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                />
                                                </div>
                                            ) : (
                                                <div
                                                className="form-group side-form-group tab tab-1"
                                                style={{ display: "block" }}
                                                >
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name="phone"
                                                    placeholder="Enter Your Phone Number"
                                                    required
                                                    min={9}
                                                    minLength={9}
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                />
                                                {errors && (
                                                    <div className="error">
                                                    Phone number must be at least 9 digits long
                                                    </div>
                                                )}
                                                </div>
                                            )}
                                            </div>
                                            <div className="banner_form_btn">
                                                {/* <button className="tab-1-btn sbt_set next" type="button"
                                                    onclick="nextPrev(1)"><i
                                                        className="fas fa-chevron-right"></i></button> */}

                                                {countstep > 0 && (
                                                <button
                                                    className="sbt_set tab-1-btn prev"
                                                    type="button"
                                                    style={{ display: "inline" }}
                                                    onClick={handlePrevClick}
                                                >
                                                    <i
                                                        class="fas fa-chevron-left"></i>
                                                </button>
                                                )}
                                                {countstep < 2 ? (
                                                <button
                                                    className="sbt_set tab-1-btn next"
                                                    type="button"
                                                    style={{ display: "inline" }}
                                                    onClick={handleNextClick}
                                                >
                                                    <i
                                                        class="fas fa-chevron-right"></i>
                                                </button>
                                                ) : loading === true ? (
                                                <div
                                                    className="learn-more-tab sbt_set"
                                                    style={{
                                                    display: "inline-block",
                                                    padding: "01.rem 0.5rem",
                                                    margin: "0px 2px",
                                                    }}
                                                >
                                                    <img src={Loader} alt="gif" width={"35px"} />
                                                </div>
                                                ) : (
                                                <button
                                                    className="learn-more-tab sbt_set"
                                                    id="signupBtn2"
                                                    name="signupForm"
                                                    type="submit"
                                                    style={{ display: "inline" }}
                                                    disabled={loading}
                                                >
                                                    <i
                                                        class="fas fa-sign-out"></i>
                                                </button>
                                                )}
                                            
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <a href="javascript:;" className="default_btn orange_btn hvr-radial-in open_popup" onClick={onOpenModal}>Start Your
                            Journey
                            Today</a>
                        <img loading="lazy" src={bannerLogos} alt="All Banner Logos" className="img-fluid"/>
                    </div>
                </div>
            </div>
        </div>

        <div className="banner_right_img">
            <div className="banner_img_box img-box-one" data-aos="fade-down-left" data-aos-duration="1500"
                data-aos-delay="800">
                <img loading="lazy" src={newBook5} className="banner_img1" alt="Now What!!!"/>
            </div>
            <div className="banner_img_box img-box-two" data-aos="fade-down-left" data-aos-duration="1500"
                data-aos-delay="500">
                <img loading="lazy" src={newBook6} className="banner_img1" alt="Subtle Sublimity"/>
            </div>
            <div className="banner_img_box img-box-three" data-aos="fade-down-left" data-aos-duration="1500"
                data-aos-delay="200">
                <img loading="lazy" src={newBook7} className="banner_img1" alt="In THe Quest Forlove"/>
            </div>
            <div className="banner_img_box img-box-four" data-aos="fade-down-left" data-aos-duration="1500"
                data-aos-delay="500">
                <img loading="lazy" src={newBook8} className="banner_img1" alt="Stands Tall"/>
            </div>
        </div>
    </section>
    <ModalForm open={open} onCloseModal={onCloseModal} />
    </div>
  )
}
