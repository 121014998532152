import React from 'react';
import ProcessShape from '../../assets/images/process-shape.webp'

export default function Process() {
  return (
    <div>
      <section className="process_sec sec_pt sec_pb">
        <div className="container position-relative">
            <div className="sec_title text-center" data-aos="fade-down" data-aos-duration="1500">
                <h2>OUR PROCESS INCLUDES FOUR EASY STEPS!</h2>
            </div>
            <div className="process_wrapper">
                <div className="row no-gutters process_row1">
                    <div className="col-lg-4 offset-2" data-aos="fade-right" data-aos-duration="1500">
                        <div className="process_box1">
                            <h3>SIGNING UP</h3>
                            <p>Feel free to get in touch with us by signing up for a live chat. Even if you are a little
                                shy
                                to come on call, you can leave us a text message or an email and wait for our swift
                                response. </p>
                        </div>
                    </div>
                </div>
                <div className="row no-gutters process_row2">
                    <div className="col-lg-4">
                        <div className="process_box2" data-aos="fade-right" data-aos-duration="1500">
                            <h3>RESEARCH AND DRAFTING </h3>
                            <p>Place and confirm your first order and leave the rest to us. Our professional
                                ghostwriters
                                will start their research to construct an outline for your book so that we can get
                                closer to
                                the next step, respectively known as writing. </p>
                        </div>
                    </div>
                    <div className="col-lg-4 offset-4" data-aos="fade-left" data-aos-duration="1500">
                        <div className="process_box3">
                            <h3>
                                WRITING AND REVISION
                            </h3>
                            <p>Our experienced ghostwriters don’t believe in wasting a single second. Hence, right after
                                the research, it's time to put all the work together. However, we’ll be needing your
                                feedback now and then just to make sure there is any revision needed. </p>
                        </div>
                    </div>
                </div>
                <div className="row no-gutters process_row3">
                    <div className="col-lg-4 offset-6">
                        <div className="process_box4" data-aos="fade-left" data-aos-duration="1500">
                            <h3>FORMATTING AND PUBLISHING</h3>
                            <p>Last and certainly not the least. After completing all the work, now is the time to work
                                on your book’s appearance. Formatting a book includes graphic and font designs alongside
                                the front and back cover of the book. And then it’s ready to go live!</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="process_mob row process_wrapper_Slider">
                <div className="col-md-6">
                    <div className="process_mb_box">
                        <div className="sno">
                            01
                        </div>
                        <div className="process_mob_det">
                            <h3>Sign Up</h3>
                            <p>Call us or get in touch with us through live chat to place an order with us.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="process_mb_box">
                        <div className="sno">
                            02
                        </div>
                        <div className="process_mob_det">
                            <h3>Assigning Writer</h3>
                            <p>We assign your project to an expert writer <br/>relevant to your book's genre.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="process_mb_box">
                        <div className="sno">
                            03
                        </div>
                        <div className="process_mob_det">
                            <h3>Writing & Revisions</h3>
                            <p>The writer starts working on your order right away, takes your feedback on it, & revises
                                it
                                if need be.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="process_mb_box">
                        <div className="sno">
                            04
                        </div>
                        <div className="process_mob_det">
                            <h3>Delivery</h3>
                            <p>We send the completed order to your email & account. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img loading="lazy" src={ProcessShape} className="process_shape" alt="OUR PROCESS INCLUDES FOUR EASY STEPS!"/>
    </section>
    </div>
  )
}
