import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './module/home';
import { ThankYou } from './module/thankyou';


function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/thankyou' element={<ThankYou/>}/>
      </Routes>
    
    </div>
  );
}

export default App;
