import React from 'react'

export default function BookSec() {
  return (
    <div>
      <section className="own-book-sec should-hire">
        <div className="container-fluid">
            <div className="own-book-inner-txt">
                <div className="wrapper-own-book-1">
                    <h4 data-aos="fade-up">Why is it Important to Hire a Ghostwriting Agency?</h4>
                    <div className="row">
                        <div className="col-lg-6">
                            <p data-aos="fade-up">Some may say hiring a ghostwriter or any writer for your project is
                                unethical, and we beg to differ. Only some people have the time to compile and publish
                                all their thoughts so that the world can know their stories. Therefore, hiring a
                                professional is the best way to make it happen quickly. Besides, how you gather your
                                thoughts isn’t similar to how you write them on paper or in your drive. Writing is an
                                art; not everyone is experienced enough to master it. </p>
                            <p data-aos="fade-up">Just as the name shows, a ghostwriter might ghost you for a while, but
                                it has nothing to do with the fact that it's just his way of working. Do you remember
                                the saying of Ezio Auditore da Firenze: “We work in the dark to serve the light.” right?
                                The same is true with ghostwriters, they don’t come out often, but when they do, it's
                                for the right cause! Maybe just to publish your next book. </p>

                        </div>
                        <div className="col-lg-6">
                            <p data-aos="fade-up">Not knowing how to manage time wisely can kill many hobbies, and we
                                don’t want that to happen to you. Hence, we decided to bring you the best ghostwriting
                                services. Easy on pockets and heavy on a reader’s heart. Apart from all that we
                                mentioned above, here is the best reason why you require a ghostwriter even after you
                                can write your book. Well, because everyone has their own set of opinions and
                                perspectives. Although you aren’t obliged to someone else’s perspective, it won’t hurt
                                to consider that just for a change. </p>
                            <p data-aos="fade-up">Say, you did all the work already; now you need an expert opinion to
                                move forward. A professional ghostwriter is the only person who can provide you with the
                                best and most reasonable opinion. Why? Because he has worked on multiple projects and
                                niches at a time. You can trust a professional when it comes to writing. Ghostwriters
                                are always non-biased and believe in constructive criticism. Plus, you’ll have your very
                                own book published. </p>
                        </div>
                    </div>
                </div>

                <div className="wrapper-own-book-2">
                    <h4 data-aos="fade-up">Hiring a Ghostwriter Can Be Tiring, But Not With Us!</h4>
                    <div className="row">
                        <div className="col-lg-6">
                            <p data-aos="fade-up">Handing over your project to someone you don’t know can be suspicious,
                                but that is exactly how ghostwriting works. You will have to trust the process to get
                                things done. Although, no matter how exhausting it can get, if you have a trusted agency
                                like ours in your contacts, everything will be as smooth as butter. </p>
                            <p data-aos="fade-up">We only need a little detail about your story and a brief on how you
                                want your book to be composed. Whether you wrote down everything precisely or it's rough
                                work, our ghostwriting services can handle that. However, your cooperation might help a
                                lot in between since it’s your story, and we are just providing you with professional
                                help. </p>
                            <p data-aos="fade-up">Speaking of which, there is a tiny possibility of the whole process
                                taking a toll on you if you haven’t done this before. Don’t worry if you can’t get the
                                desired results immediately because things take time! However, the good news is that you
                                won’t regret opting for our affordable ghostwriting services. We have some great writers
                                on board who would treat your project as their own. </p>
                            <p data-aos="fade-up">Another thing that you must consider before contacting a ghostwriter
                                company is that our writers will be working on your ideas. Though, you can expect
                                suggestions on minor changes from a ghostwriter throughout the process. Nonetheless,
                                changes will only be made until or unless you approve them. The outline must be agreed
                                upon beforehand so you and the writer can find a middle ground. However, you have full
                                authority to include or exclude anything from the manuscript. </p>
                        </div>
                        <div className="col-lg-6">
                            <p data-aos="fade-up">Although the process is pretty serene, our ghostwriter won’t move
                                forward with the next chapter unless you give him a green signal for the previous
                                chapter. Each chapter of your book requires separate feedback so revisions can be made
                                on time and it does not break the flow. Once the writer completes all the branches, we
                                will proceed to the next step, editing and drafting. </p>
                            <p data-aos="fade-up">Now that we have a complete manuscript, it’s time to work on fonts,
                                graphics, <a href="book-cover-designer">cover</a>, and grammatical errors. This might
                                feel a little more time-consuming than writing a book. You may wonder why; well, when
                                writing a book, you are just writing—but editing needs proper concentration since it
                                includes proofreading each sentence repeatedly until it sounds just about right! </p>
                            <p data-aos="fade-up">Apart from <a href="book-proofreaders">proofreading</a> and <a
                                    href="book-editing">editing,</a> this is where we work on your book’s appearance
                                because the cover of your book says a lot, right? Yes, we know a book should not be
                                judged by its cover, but do you know anyone who doesn’t do that? Let’s agree that
                                everyone judges a book by its cover and decides whether they should read it. However,
                                that’s a whole other story. </p>
                            <p data-aos="fade-up">When you hire us for your project, we ensure that every minor detail
                                of your book and its appearance should be completely covered. We keep an eye on it
                                mainly to meet the quality standards of publishing a book. This helps you with the
                                marketing of your book as well. Plus, the more people see it, the more they talk about
                                it. Now that we have mentioned everything from spotting professional ghostwriters to
                                hiring them, we assume you got all the answers to your queries. So, when are you
                                planning to publish your book? Get it done already! </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}
