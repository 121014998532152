
import React, {useState} from 'react'
import { img } from '../../assets';

import ModalForm from '../../module/modalPopup';
import { NavLink } from 'react-router-dom';

export default function Header() {
    const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };



    
  return (
    <div>
      <div className="header_top">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6 col-md-8 col-sm-9 col-4">
              <ul className="top_left">
                <li>
                  <a href="tel:3236094870">
                    <img loading="lazy" src={img.PhoneIcon} alt="phone icon" />
                    <span>323 609 4870</span>
                  </a>
                </li>
                <li>
                  <a href="mailto:info@professionalwritershelp.co">
                    <img loading="lazy" src={img.EmailIcon} alt="email icon" />
                    <span>info@professionalwritershelp.co</span>
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="col-lg-6 col-md-4 col-sm-3 col-8"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <ul className="top_right">
                <li>
                  <a href="https://www.facebook.com/profile.php?id=100088823932310">
                    <i className="fab fa-facebook-square"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/professionalwritershelp/">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.pinterest.com/professionalwritershelp/">
                    <i className="fab fa-pinterest"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/88426244/admin/">
                    <i className="fab fa-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="header_bottom">
        <div className="container-fluid">
          <nav className="navbar row justify-content-between align-items-center navbar-expand-lg navbar-light bg-light">
            <div
              className="header_left"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <NavLink className="navbar-brand main_logo" to="/">
                <img
                  loading="lazy"
                  src={img.Logo}
                  alt="PWH Logo NEW"
                  width="331"
                  height="67"
                  className="img-fluid lazyload"
                />
              </NavLink>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div className="header_center">
              <div
                className="collapse navbar-collapse justify-content-center"
                id="navbarNav"
              >
                <ul className="navbar-nav cust-navbar-nav">
                  <li className="nav-item active">
                    <a className="nav-link" href="/">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="about-us">
                      About Us
                    </a>
                  </li>
                  <li className="nav-item dropdown cust-dropdown mega-menu ">
                    <span
                      className="dropdowndownangle drop-icon"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      aria-label="navbarDropdown"
                    ></span>
                    <a className="nav-link dropdown-toggle" href="javascript:;">
                      Ghostwriting
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <div className="row no-gutters">
                        <div className="col-lg-3">
                          <ul className="dropdown-list">
                            <li>
                              <a href="story-writing">Story Writing</a>
                            </li>
                            <li>
                              <a href="song-writing">Song Writing</a>
                            </li>
                            <li>
                              <a href="memoir-writing">Memoir Writing</a>
                            </li>
                            <li>
                              <a href="nonfiction-writing">
                                Non-Fiction Writing
                              </a>
                            </li>
                            <li>
                              <a href="rhymes-writing">Rhymes Writing</a>
                            </li>
                            <li>
                              <a href="ebook-writing">Ebook Writing</a>
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-3">
                          <ul className="dropdown-list">
                            <li>
                              <a href="biography-writing">Biography Writing</a>
                            </li>
                            <li>
                              <a href="autobiography-writing">Autobiography</a>
                            </li>
                            <li>
                              <a href="seo-content-writing">
                                SEO Content Writing
                              </a>
                            </li>
                            <li>
                              <a href="hiphop-writing">Hip Hop Writing</a>
                            </li>
                            <li>
                              <a href="script-writing">Script Writing</a>
                            </li>
                            <li>
                              <a href="narrative-writing">Narrative Writing</a>
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-3">
                          <ul className="dropdown-list">
                            <li>
                              <a href="novel-writing">Novel Writing</a>
                            </li>
                            <li>
                              <a href="wiki-writing">Wiki Writing</a>
                            </li>
                            <li>
                              <a href="speech-writing">Speech Writing</a>
                            </li>
                            <li>
                              <a href="comedy-writing">Comedy Writing</a>
                            </li>
                            <li>
                              <a href="movie-writing">Movie Writing</a>
                            </li>
                            <li>
                              <a href="screen-writing">Screen Writing</a>
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-3">
                          <ul className="dropdown-list">
                            <li>
                              <a href="military-fiction-writing">
                                Military Fiction writing
                              </a>
                            </li>
                            <li>
                              <a href="comic-writing">Comic Book writing</a>
                            </li>
                            <li>
                              <a href="children-book-writing">
                                Children’s Books
                              </a>
                            </li>
                            <li>
                              <a href="business-book-ghostwriting">
                                Business Book
                              </a>
                            </li>
                            <li>
                              <a href="book-writing-services">
                                Book Writing Services
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item dropdown cust-dropdown mega-menu ">
                    <span
                      className="dropdowndownangle drop-icon"
                      id="navbarDropdownone"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      aria-label="navbarDropdownone"
                    ></span>
                    <a className="nav-link dropdown-toggle" href="javascript:;">
                      Fiction Writing
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownone"
                    >
                      <ul className="dropdown-list">
                        <li>
                          <a href="action-adventure-writing">
                            Action Adventure{' '}
                          </a>
                        </li>

                        <li>
                          <a href="fantasy-writing">Fantasy Writing</a>
                        </li>
                        <li>
                          <a href="scifi-writing">Sci-Fi writing</a>
                        </li>
                        <li>
                          <a href="horror-book-writing">Horror Writing</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="nav-item dropdown cust-dropdown mega-menu ">
                    <span
                      className="dropdowndownangle drop-icon"
                      id="navbarDropdowntwo"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      aria-label="navbarDropdowntwo"
                    ></span>
                    <a className="nav-link dropdown-toggle" href="javascript:;">
                      Books Editing
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdowntwo"
                    >
                      <ul className="dropdown-list">
                        <li>
                          <a href="book-formatting">Book Formatting</a>
                        </li>
                        <li>
                          <a href="children-book-editing">
                            Children's Book Editors
                          </a>
                        </li>
                        <li>
                          <a href="book-proofreaders">Book Proofreading</a>
                        </li>
                        <li>
                          <a href="book-editing">Book Editing</a>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="book-marketing-services">
                      Book Marketing
                    </a>
                  </li>
                  <li className="nav-item dropdown cust-dropdown mega-menu ">
                    <span
                      className="dropdowndownangle drop-icon"
                      id="navbarDropdownthree"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      aria-label="navbarDropdownthree"
                    ></span>
                    <a className="nav-link dropdown-toggle" href="javascript:;">
                      Other Services
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownthree"
                    >
                      <ul className="dropdown-list">
                        <li>
                          <a href="book-cover-designer">Book Cover Design</a>
                        </li>
                        <li>
                          <a href="audiobook-narrators">Book Audio</a>
                        </li>
                        <li>
                          <a href="book-video-production">Book Trailer</a>
                        </li>
                        <li>
                          <a href="author-website-design">
                            Author Website Design
                          </a>
                        </li>
                        <li>
                          <a href="book-publication-company">Book Publishing</a>
                        </li>
                        <li>
                          <a href="children-book-printing">
                            Children's Book Printing
                          </a>
                        </li>
                        <li>
                          <a href="book-illustration-services">
                            Book Illustration Services
                          </a>
                        </li>
                        <li>
                          <a href="comic-book-illustration">
                            Comic Book Illustration
                          </a>
                        </li>
                        <li>
                          <a href="children-book-illustration">
                            Children's Book Illustration
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="contact-us">
                      Contact Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="blogs">
                      Blogs
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="header_right"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <ul className="header_btn text-right">
                <li>
                  <a
                    href="javascript:;"
                    onClick={onOpenModal}
                    className="default_btn orange_btn hvr-radial-in open_popup"
                  >
                    Let's Get Started
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <ModalForm open={open} onCloseModal={onCloseModal} />
    </div>
  );
}
