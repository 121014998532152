import React, { useState } from "react";
import {img} from "../../assets";
// import AboutImg1 from "../../../public/assets/imgs/about-img1.webp";
// import AboutImg2 from "../../../public/assets/imgs/about-img2.webp";
// import AboutImg3 from "../../../public/assets/imgs/about-img3.webp";
// import AboutImg4 from "../../../public/assets/imgs/about-img4.webp";
// import AboutGirl from "../../../public/assets/imgs/about-girl.webp";

import ModalForm from "../modalPopup";

export default function About() {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => {
      setOpen(true);
  };

  const onCloseModal = () => {
      setOpen(false);
  };

  return (
    <div>
      <section className="about sec_pb sec_pt">
        <div className="container">
          <div className="row justify-content-end">
            <div
              className="col-lg-8"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <h2>A little something about Ghostwriting company!</h2>
              <p>
                We started as a rookie, and it took us a long time to be here,
                recognized as one of the market's most professional and best
                ghostwriting companies. You can count on us whether you need an
                expert opinion on your write-ups,{" "}
                <a href="book-proofreaders">proofreading,</a>{" "}
                <a href="book-editing">editing,</a> or ghostwriting services in
                any possible niche. From action fiction to your life story, we
                have a wide range of writers who would gladly work on your
                project without hesitation!{" "}
              </p>
              <div className="row">
                <div className="col-md-3 col-6">
                  <div className="about_box">
                    <div className="about_icon">
                      <img
                        loading="lazy"
                        src={img.AboutImg1}
                        alt="High Quality Content"
                      />
                    </div>
                    <h3>
                      High Quality <br />
                      Content
                    </h3>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="about_box">
                    <div className="about_icon">
                      <img
                        loading="lazy"
                        src={img.AboutImg2}
                        alt="Rapid Turnaround"
                      />
                    </div>
                    <h3>
                      Rapid <br />
                      Turnaround
                    </h3>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="about_box">
                    <div className="about_icon">
                      <img
                        loading="lazy"
                        src={img.AboutImg3}
                        alt="Value For Money"
                      />
                    </div>
                    <h3>
                      Value For <br />
                      Money
                    </h3>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="about_box">
                    <div className="about_icon">
                      <img
                        loading="lazy"
                        src={img.AboutImg4}
                        alt="Quality Checked"
                      />
                    </div>
                    <h3>
                      Quality <br />
                      Checked
                    </h3>
                  </div>
                </div>
              </div>
              <a
                href="javascript:;"
                className="default_btn orange_btn btn_space hvr-radial-in open_popup"
                onClick={onOpenModal}
              >
                Let's Get Started
              </a>
              <a
                href="javascript:;"
                className="default_btn black_btn hvr-radial-in open_popup"
                onClick={onOpenModal}
              >
                Request Free Consultation
              </a>
            </div>
          </div>
        </div>
        <img
          loading="lazy"
          src={img.AboutGirl}
          alt="About - Professional Writers Help"
          className="about_girl"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="200"
        />
      </section>

      <ModalForm open={open} onCloseModal={onCloseModal} />
    </div>
  );
}
