import React from 'react';
import footerLogo from '../../assets/images/footer-logo-new.webp'
import paymentMethod from '../../assets/images/payment-methods.webp'

export default function Footer() {
  return (
    <div>
        <footer class="main-footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-sm-8">
                        <div class="footer-detail-area">
                            <a href="/">
                                <img alt="PWH logo Footer" class="img-fluid footer_logo" src={footerLogo}/>
                            </a>
                            <ul>
                                <li><a href="javascript:;"><i class="fas fa-map-marker-alt"></i>
                                453 S Spring St Ste 400 PMB 190, Los Angeles, CA 90013</a>
                                </li>
                                <li><a href="tel:3236094870"><i class="fas fa-phone" aria-hidden="true"></i> 323 609 4870</a>
                                </li>
                                <li><a href="mailto:info@professionalwritershelp.co"><i class="fas fa-envelope" aria-hidden="true"></i>info@professionalwritershelp.co</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 col-sm-4 ">
                        <div class="footer-menu-wrap">
                            <div class="about-menu">
                                <h3>Quick Links</h3>
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li><a href="about-us">About Us</a></li>
                                    <li><a href="contact-us">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-sm-12">
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="footer-menu-wrap services-menu">
                                    <h3>Services</h3>
                                    <ul>
                                        <li><a href="story-writing">Story Writing</a></li>
                                        <li><a href="song-writing">Song Writing</a></li>
                                        <li><a href="memoir-writing">Memoir Writing</a></li>
                                        <li><a href="nonfiction-writing">Non-Fiction Writing</a></li>
                                        <li><a href="rhymes-writing">Rhymes Writing</a></li>
                                        <li><a href="children-book-writing">Children's Book Writing</a></li>
                                        <li><a href="biography-writing">Biography Writing</a></li>
                                        <li><a href="business-book-ghostwriting">Business Ghostwriting</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="footer-menu-wrap services-menu">
                                    <h3>Services</h3>
                                    <ul>
                                        <li><a href="seo-content-writing">SEO Content Writing</a></li>
                                        <li><a href="hiphop-writing">Hip Hop Writing</a></li>
                                        <li><a href="script-writing">Script Writing</a></li>
                                        <li><a href="narrative-writing">Narrative Writing</a></li>
                                        <li><a href="novel-writing">Novel Writing</a></li>
                                        <li><a href="wiki-writing">Wiki Writing</a></li>
                                        <li><a href="speech-writing">Speech Writing</a></li>
                                        <li><a href="military-fiction-writing">Military Fiction Writing</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="footer-menu-wrap services-menu">
                                    <h3>Services</h3>
                                    <ul>
                                        <li><a href="comic-writing">Comic Book Writing</a></li>
                                        <li><a href="comedy-writing">Comedy Writing</a></li>
                                        <li><a href="movie-writing">Movie Writing</a></li>
                                        <li><a href="screen-writing">Screen Writing</a></li>
                                        <li><a href="autobiography-writing">Autobiography Writing</a></li>
                                        <li><a href="ebook-writing">Ebook Writing</a></li>
                                        <li><a href="book-marketing-services">Book Marketing</a></li>
                                        <li><a href="book-writing-services">Book Writing Services</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        <div class="copyright">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 text-md-center text-lg-left">
                        <p>© 2024 - All Rights Reserved, Professional Writers Help</p>
                    </div>
                    <div class="col-lg-4 text-center">
                        <img loading="lazy" src={paymentMethod} alt="Payment Method we accept"/>
                    </div>
                    <div class="col-lg-4 text-lg-right text-md-center">
                        <p><a href="javascript:;">Terms of Services</a><a
                                href="javascript:;">Privacy Policy</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
