import React, {useState} from 'react'
import CtaOneImg from '../../assets/images/cta-one-book.webp';
import ModalForm from '../modalPopup';

export default function CtaOne() {

  const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };

  return (
    <div>
      <section className="cta_one">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8 text-center" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500">
                    <h2>Our Expert Ghost Writers Can Help You <br/><span className="clr_white">Turn Your Dreams Into A
                            Reality</span></h2>
                    <p>Our team comprises of bookworms and literature-nerds but they’re also professionally trained as
                        writers.</p>
                    <a href="javascript:;"  onClick={onOpenModal} className="default_btn white_btn btn_space hvr-radial-in open_popup">Let's Get
                        Started</a>
                    <a href="javascript:;"   onClick={onOpenModal} className="default_btn black_btn hvr-radial-in open_popup">Request Free
                        Consultation</a>
                </div>
            </div>
        </div>
        <img loading="lazy" src={CtaOneImg} alt="Get a Quote" className="cta_one_book" data-aos="fade-right"
            data-aos-duration="1500"/>
    </section>

    <ModalForm open={open} onCloseModal={onCloseModal} />

    </div>
  )
}
