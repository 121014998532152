import React, { useState, useEffect } from "react";

import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { Fancybox as NativeFancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

import { img } from "../../assets";
import ModalForm from "../modalPopup";

export default function PortfolioSec() {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };
  useEffect(() => {
    NativeFancybox.bind("[data-fancybox]", {
      Carousel: {
        infinite: false,
      },
    });

    return () => {
      NativeFancybox.unbind("[data-fancybox]");
    };
  }, []);
  return (
    <div>
      <section class="portfolio_sec sec_pt sec_pb">
        <div class="container">
          <div class="sec_title text-center white_hd">
            <h2 data-aos="fade-up" data-aos-duration="1500">
              Our Portfolio
            </h2>
          </div>
          <div class="portfolio_tabs">
            <Tab.Container defaultActiveKey="nav0">
              <Nav variant="pills" className="nav-tabs">
                <Nav.Item>
                  <Nav.Link eventKey="nav0">All</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="nav1">Fiction</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="nav2">Biography</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="nav3">Non-Fiction</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="nav4">Informative</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="nav5">Memoir</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="nav6">Children's Books</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="nav0">
                  <div class="row">
                    <div
                      class="col-md-3 col-sm-6 col-6 mb-4"
                      data-aos="fade-up"
                      data-aos-delay="100"
                      data-aos-duration="1500"
                    >
                     
                        <a
                         className="portfolio-box"
                         data-aos="zoom-in"
                         data-aos-duration="1000"
                         data-fancybox="port"
                         href={img.allTab1}
                        >
                          <img
                            loading="lazy"
                            src={img.allTab1}
                            alt="portfolio img 1"
                          />
                          <img
                            loading="lazy"
                            src={img.portIcon}
                            alt="Portfolio Open Button"
                          />
                        </a>
                      
                    </div>
                    <div
                      class="col-md-3 col-sm-6 col-6 mb-4"
                      data-aos="fade-up"
                      data-aos-delay="200"
                      data-aos-duration="1500"
                      
                    >
                      <a
                        href={img.allTab2}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.allTab2}
                          alt="portfolio img 2"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div
                      class="col-md-3 col-sm-6 col-6 mb-4"
                      data-aos="fade-up"
                      data-aos-delay="300"
                      data-aos-duration="1500"
                    >
                      <a
                        href={img.allTab3}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.allTab3}
                          alt="portfolio img 3"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div
                      class="col-md-3 col-sm-6 col-6 mb-4"
                      data-aos="fade-up"
                      data-aos-delay="400"
                      data-aos-duration="1500"
                    >
                      <a
                        href={img.allTab4}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.allTab4}
                          alt="portfolio img 4"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div
                      class="col-md-3 col-sm-6 col-6 mb-4"
                      data-aos="fade-up"
                      data-aos-delay="400"
                      data-aos-duration="1500"
                    >
                      <a
                        href={img.allTab5}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.allTab5}
                          alt="portfolio img 5"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div
                      class="col-md-3 col-sm-6 col-6 mb-4"
                      data-aos="fade-up"
                      data-aos-delay="400"
                      data-aos-duration="1500"
                    >
                      <a
                        href={img.allTab6}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.allTab6}
                          alt="portfolio img 6"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div
                      class="col-md-3 col-sm-6 col-6 mb-4"
                      data-aos="fade-up"
                      data-aos-delay="400"
                      data-aos-duration="1500"
                    >
                      <a
                        href={img.allTab7}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.allTab7}
                          alt="portfolio img 7"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div
                      class="col-md-3 col-sm-6 col-6 mb-4"
                      data-aos="fade-up"
                      data-aos-delay="400"
                      data-aos-duration="1500"
                    >
                      <a
                        href={img.allTab8}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.allTab8}
                          alt="portfolio img 7"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div
                      class="col-md-3 col-sm-6 col-6"
                      data-aos="fade-up"
                      data-aos-delay="400"
                      data-aos-duration="1500"
                    >
                      <a
                        href={img.allTab9}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.allTab9}
                          alt="portfolio img 8"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div
                      class="col-md-3 col-sm-6 col-6"
                      data-aos="fade-up"
                      data-aos-delay="400"
                      data-aos-duration="1500"
                    >
                      <a
                        href={img.allTab10}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.allTab10}
                          alt="portfolio img 9"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div
                      class="col-md-3 col-sm-6 col-6"
                      data-aos="fade-up"
                      data-aos-delay="400"
                      data-aos-duration="1500"
                    >
                      <a
                        href={img.allTab11}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.allTab11}
                          alt="portfolio img 10"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div
                      class="col-md-3 col-sm-6 col-6"
                      data-aos="fade-up"
                      data-aos-delay="400"
                      data-aos-duration="1500"
                    >
                      <a
                        href={img.allTab12}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.allTab12}
                          alt="portfolio img 11"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="nav1">
                  <div class="row">
                    <div
                      class="col-md-3 col-sm-6 col-6 mb-4"
                      data-aos="fade-up"
                      data-aos-delay="100"
                      data-aos-duration="1500"
                    >
                      <a
                        href={img.Fiction1}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Fiction1}
                          alt="portfolio img 12"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div
                      class="col-md-3 col-sm-6 col-6 mb-4"
                      data-aos="fade-up"
                      data-aos-delay="200"
                      data-aos-duration="1500"
                    >
                      <a
                        href={img.Fiction2}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Fiction2}
                          alt="fiction img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div
                      class="col-md-3 col-sm-6 col-6 mb-4"
                      data-aos="fade-up"
                      data-aos-delay="300"
                      data-aos-duration="1500"
                    >
                      <a
                        href={img.Fiction3}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Fiction3}
                          alt="fiction img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div
                      class="col-md-3 col-sm-6 col-6 mb-4"
                      data-aos="fade-up"
                      data-aos-delay="400"
                      data-aos-duration="1500"
                    >
                      <a
                        href={img.Fiction4}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Fiction4}
                          alt="fiction img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div
                      class="col-md-3 col-sm-6 col-6 mb-4"
                      data-aos="fade-up"
                      data-aos-delay="400"
                      data-aos-duration="1500"
                    >
                      <a
                        href={img.Fiction5}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Fiction5}
                          alt="fiction img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div
                      class="col-md-3 col-sm-6 col-6 mb-4"
                      data-aos="fade-up"
                      data-aos-delay="400"
                      data-aos-duration="1500"
                    >
                      <a
                        href={img.Fiction6}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Fiction6}
                          alt="fiction img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div
                      class="col-md-3 col-sm-6 col-6 mb-4"
                      data-aos="fade-up"
                      data-aos-delay="400"
                      data-aos-duration="1500"
                    >
                      <a
                        href={img.Fiction7}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Fiction7}
                          alt="fiction img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div
                      class="col-md-3 col-sm-6 col-6 mb-4"
                      data-aos="fade-up"
                      data-aos-delay="400"
                      data-aos-duration="1500"
                    >
                      <a
                        href={img.Fiction8}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Fiction8}
                          alt="fiction img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div
                      class="col-md-3 col-sm-6 col-6"
                      data-aos="fade-up"
                      data-aos-delay="400"
                      data-aos-duration="1500"
                    >
                      <a
                        href={img.Fiction9}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Fiction9}
                          alt="fiction img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div
                      class="col-md-3 col-sm-6 col-6"
                      data-aos="fade-up"
                      data-aos-delay="400"
                      data-aos-duration="1500"
                    >
                      <a
                        href={img.Fiction10}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Fiction10}
                          alt="fiction img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div
                      class="col-md-3 col-sm-6 col-6"
                      data-aos="fade-up"
                      data-aos-delay="400"
                      data-aos-duration="1500"
                    >
                      <a
                        href={img.Fiction11}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Fiction11}
                          alt="fiction img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div
                      class="col-md-3 col-sm-6 col-6"
                      data-aos="fade-up"
                      data-aos-delay="400"
                      data-aos-duration="1500"
                    >
                      <a
                        href={img.Fiction12}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Fiction12}
                          alt="fiction img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="nav2">
                  <div class="row">
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.Bio1}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Bio1}
                          alt="Biography img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.Bio2}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Bio2}
                          alt="Biography img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.Bio3}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Bio3}
                          alt="Biography img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.Bio4}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Bio4}
                          alt="Biography img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.Bio5}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Bio5}
                          alt="Biography img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.Bio6}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Bio6}
                          alt="Biography img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.Bio7}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Bio7}
                          alt="Biography img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.Bio8}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Bio8}
                          alt="Biography img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6">
                      <a
                        href={img.Bio9}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Bio9}
                          alt="Biography img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6">
                      <a
                        href={img.Bio10}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Bio10}
                          alt="Biography img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6">
                      <a
                        href={img.Bio11}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Bio11}
                          alt="Biography img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6">
                      <a
                        href={img.Bio12}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Bio12}
                          alt="Biography img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="nav3">
                  <div class="row">
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.nonBio1}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.nonBio1}
                          alt="Non-Fiction img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.nonBio2}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.nonBio2}
                          alt="Non-Fiction img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.nonBio3}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.nonBio3}
                          alt="Non-Fiction img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.nonBio4}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.nonBio4}
                          alt="Non-Fiction img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.nonBio5}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.nonBio5}
                          alt="Non-Fiction img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.nonBio6}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.nonBio6}
                          alt="Non-Fiction img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.nonBio7}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.nonBio7}
                          alt="Non-Fiction img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.nonBio8}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.nonBio8}
                          alt="Non-Fiction img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6">
                      <a
                        href={img.nonBio9}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.nonBio9}
                          alt="Non-Fiction img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6">
                      <a
                        href={img.nonBio10}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.nonBio10}
                          alt="Non-Fiction img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6">
                      <a
                        href={img.nonBio11}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.nonBio11}
                          alt="Non-Fiction img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6">
                      <a
                        href={img.nonBio12}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.nonBio12}
                          alt="Non-Fiction img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="nav4">
                  <div class="row">
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.Informative1}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Informative1}
                          alt="informative img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.Informative2}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Informative2}
                          alt="informative img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.Informative3}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Informative3}
                          alt="informative img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.Informative4}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Informative4}
                          alt="informative img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.Informative5}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Informative5}
                          alt="informative img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.Informative6}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Informative6}
                          alt="informative img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.Informative7}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Informative7}
                          alt="informative img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.Informative8}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Informative8}
                          alt="informative img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6">
                      <a
                        href={img.Informative9}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Informative9}
                          alt="informative img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6">
                      <a
                        href={img.Informative10}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Informative10}
                          alt="informative img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6">
                      <a
                        href={img.Informative11}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Informative11}
                          alt="informative img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6">
                      <a
                        href={img.Informative12}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Informative12}
                          alt="informative img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="nav5">
                  <div class="row">
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.Memoir1}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Memoir1}
                          alt="Memoir img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.Memoir2}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Memoir2}
                          alt="Memoir img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.Memoir3}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Memoir3}
                          alt="Memoir img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.Memoir4}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Memoir4}
                          alt="Memoir img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.Memoir5}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Memoir5}
                          alt="Memoir img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.Memoir6}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Memoir6}
                          alt="Memoir img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.Memoir7}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Memoir7}
                          alt="Memoir img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.Memoir8}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Memoir8}
                          alt="Memoir img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6">
                      <a
                        href={img.Memoir9}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Memoir9}
                          alt="Memoir img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6">
                      <a
                        href={img.Memoir10}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Memoir10}
                          alt="Memoir img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6">
                      <a
                        href={img.Memoir11}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Memoir11}
                          alt="Memoir img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6">
                      <a
                        href={img.Memoir12}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.Memoir12}
                          alt="Memoir img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="nav6">
                  <div class="row">
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.childBook1}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.childBook1}
                          alt="child-book img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.childBook2}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.childBook2}
                          alt="child-book img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.childBook3}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.childBook3}
                          alt="child-book img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.childBook4}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.childBook4}
                          alt="child-book img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.childBook5}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.childBook5}
                          alt="child-book img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.childBook6}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.childBook6}
                          alt="child-book img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.childBook7}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.childBook7}
                          alt="child-book img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a
                        href={img.childBook8}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.childBook8}
                          alt="child-book img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6">
                      <a
                        href={img.childBook9}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.childBook9}
                          alt="child-book img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6">
                      <a
                        href={img.childBook10}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.childBook10}
                          alt="child-book img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6">
                      <a
                        href={img.childBook11}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.childBook11}
                          alt="child-book img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6">
                      <a
                        href={img.childBook12}
                        class="portfolio-box"
                        data-fancybox="port"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <img
                          loading="lazy"
                          src={img.childBook12}
                          alt="child-book img"
                        />
                        <img
                          loading="lazy"
                          src={img.portIcon}
                          alt="Portfolio Open Button"
                        />
                      </a>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
          <div
            class="sec_btn text-center"
            data-aos="fade-up"
            data-aos-delay="500"
            data-aos-duration="1500"
          >
            <a
              href="javascript:;"
              onClick={onOpenModal}
              class="default_btn orange_btn btn_space hvr-radial-in open_popup"
            >
              Let's Get Started
            </a>
            <a
              href="javascript:;"
              onClick={onOpenModal}
              class="default_btn white_btn hvr-radial-in open_popup"
            >
              Request Free Consultation
            </a>
          </div>
        </div>
      </section>
      <ModalForm open={open} onCloseModal={onCloseModal} />
    </div>
  );
}
