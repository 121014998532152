import React, {useState} from 'react';

export default function FAQs() {
  const faqData = [
    {
      question: `Is Ghostwriting Illegal?`,
      answer: `Ghostwriting is not illegal. It's a legitimate practice where a writer creates content on behalf of someone else, often without credit. It's essential to have clear agreements about ownership and authorship to avoid legal issues.`,
    },
    {
      question: `How Much Do Your Ghostwriting Services Cost?`,
      answer: `Ghostwriting costs vary widely based on project complexity and
                  the writer's experience. Prices can range from a few thousand
                  dollars for shorter content to tens of thousands for
                  full-length books.`,
    },
    {
      question: `Why Should I Hire A Ghostwriter?`,
      answer: `Hiring a ghostwriter can save time, ensure
                  professional-quality content, and help you express your ideas
                  effectively, especially if you lack writing skills or have a
                  busy schedule.`,
    },
    {
      question: `Do Writers Edit Or Proofread Their Work?`,
      answer: `Professional writers often edit and proofread their work to
                  ensure it's error-free and polished before submission.`,
    },
    {
      question: `How Can I Hire A Ghostwriter To Write My Book?`,
      answer: `You can hire a ghostwriter by finding one through writing
                  agencies or freelance platforms, discussing your project,
                  setting terms, and signing a contract specifying rights,
                  payment, and deadlines.`,
    },
    {
      question: `Who has the rights to my book?`,
      answer: `The rights to your book depend on your contract with the
                  ghostwriter. Typically, you retain full ownership and
                  authorship rights while the ghostwriter remains anonymous.`,
    },
    {
      question: `Are ghostwriters worth it?`,
      answer: `Ghostwriters are valuable if you need assistance with writing
                  but lack the time or skills. They can help you bring your
                  ideas to life professionally.`,
    },
    {
      question: `What are the services of a ghostwriter?`,
      answer: `Ghostwriters offer various services, including book writing,
                  article creation, blog posts, speeches, and more. They adapt
                  to your specific writing needs.`,
    },
    {
      question: `What is a ghostwriting agency?`,
      answer: `A ghostwriting agency is a company that connects clients with
                  experienced ghostwriters, helping individuals and businesses
                  find the right writer for their projects.`,
    },
    {
      question: `Do ghostwriters get royalties?`,
      answer: `Typically, ghostwriters do not receive royalties. They are
                  usually paid a one-time fee or an agreed-upon amount for their
                  work, as outlined in their contract with the client.`,
    },
  ];

  const [openIndex, setOpenIndex] = useState(0);

  const toggleFAQ = (index) => {
    console.log(index)
    setOpenIndex(openIndex === index ? -1 : index);
  };

  return (
    <div>
      <section className="faqs-section">
        <div className="container">
          <div className="text-center">
            <h2>Frequently Asked Question!</h2>
          </div>
          <div className="accordion">
            {faqData.map((faq, index) => (
              // <div key={index}>
              // <div onClick={() => toggleFAQ(index)} style={{ cursor: 'pointer', fontWeight: 'bold', margin: '10px 0' }}>
              //     {faq.question}
              // </div>
              // {openIndex === index && (
              //     <div style={{ margin: '10px 0', paddingLeft: '10px' }}>
              //     {faq.answer}
              //     </div>
              // )}
              // </div>
              <div className="accordion-item" key={index}>
                <div className={`accordion-item-header ${openIndex == index ? 'active' : ''}`} onClick={() => toggleFAQ(index)}>
                    {faq.question}
                </div>
                <div className="accordion-item-body">
                {openIndex === index && (
                  <div className="accordion-item-body-content">
                    {faq.answer}
                  </div>
                )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}
