import React from "react";
import Footer from "../../layout/footer";
import Header from "../../layout/header";
import About from "../about";
import Banner from "../banner";
import BookSec from "../booksec";
import ContactForm from "../contactform";
import CtaOne from "../ctaone";
import CtaTwo from "../ctatwo";
import FAQs from "../faqs";
import Feature from "../feature";
import OurExpertise from "../ourexpertise";
import OwnBook from "../ownbook";
import PortfolioSec from "../portfolio";
import Process from "../process";
import Services from "../services";

const Home = () => {
  return (
    <div>
      <Header />
      <Banner />
      <About />
      <OwnBook />
      <Feature />
      <CtaOne />
      <OurExpertise />
      <PortfolioSec />
      <CtaTwo />
      <Services />
      <BookSec />
      <Process />
      <ContactForm />
      <FAQs />
      <Footer />
    </div>
  );
};

export default Home;
